import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_1418e776 from 'nuxt_plugin_sentryserver_1418e776' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_a3411424 from 'nuxt_plugin_sentryclient_a3411424' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_vuetouchevents_04448bc4 from 'nuxt_plugin_vuetouchevents_04448bc4' // Source: ../plugins/vue-touch-events (mode: 'client')
import nuxt_plugin_jsonld_593ee954 from 'nuxt_plugin_jsonld_593ee954' // Source: ../plugins/jsonld (mode: 'all')
import nuxt_plugin_clickoutside_2bc8eaac from 'nuxt_plugin_clickoutside_2bc8eaac' // Source: ../plugins/click-outside (mode: 'client')
import nuxt_plugin_csrfprotection_e5627928 from 'nuxt_plugin_csrfprotection_e5627928' // Source: ../plugins/csrf-protection (mode: 'client')
import nuxt_plugin_dialog_6edfaf84 from 'nuxt_plugin_dialog_6edfaf84' // Source: ../plugins/dialog (mode: 'client')
import nuxt_plugin_filters_98405076 from 'nuxt_plugin_filters_98405076' // Source: ../plugins/filters (mode: 'all')
import nuxt_plugin_globalcomponents_91a8526c from 'nuxt_plugin_globalcomponents_91a8526c' // Source: ../plugins/global-components (mode: 'all')
import nuxt_plugin_loader_52e949ee from 'nuxt_plugin_loader_52e949ee' // Source: ../plugins/loader (mode: 'client')
import nuxt_plugin_portal_4532993c from 'nuxt_plugin_portal_4532993c' // Source: ../plugins/portal (mode: 'all')
import nuxt_plugin_redirect_7657855c from 'nuxt_plugin_redirect_7657855c' // Source: ../plugins/redirect (mode: 'client')
import nuxt_plugin_svgxuse_797b723d from 'nuxt_plugin_svgxuse_797b723d' // Source: ../plugins/svgxuse (mode: 'client')
import nuxt_plugin_tealium_ddf5c6a2 from 'nuxt_plugin_tealium_ddf5c6a2' // Source: ../plugins/tealium (mode: 'client')
import nuxt_plugin_componentlibrary_0c1b5196 from 'nuxt_plugin_componentlibrary_0c1b5196' // Source: ../plugins/component-library (mode: 'all')
import nuxt_plugin_vueasynccomputed_f271474a from 'nuxt_plugin_vueasynccomputed_f271474a' // Source: ../plugins/vue-async-computed (mode: 'client')
import nuxt_plugin_vuescroll_0c9cb62c from 'nuxt_plugin_vuescroll_0c9cb62c' // Source: ../plugins/vue-scroll (mode: 'client')
import nuxt_plugin_smoothscroll_68d9071e from 'nuxt_plugin_smoothscroll_68d9071e' // Source: ../plugins/smoothscroll (mode: 'client')
import nuxt_plugin_trackingfb_a778f3ca from 'nuxt_plugin_trackingfb_a778f3ca' // Source: ../plugins/tracking-fb.js (mode: 'client')
import nuxt_plugin_expresscheckout_226fe30e from 'nuxt_plugin_expresscheckout_226fe30e' // Source: ../plugins/express-checkout (mode: 'client')
import nuxt_plugin_vueflipcountdown_13feb424 from 'nuxt_plugin_vueflipcountdown_13feb424' // Source: ../plugins/vue-flip-countdown (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"SmartHome entdecken | Telekom","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"google-site-verification","content":"Fv9wQ1wDPQTJLA1vfrrRnih9CIf7cTqTYkErsCt1UBU"}],"script":[{"src":"https:\u002F\u002Febs01.telekom.de\u002Fresout\u002Fpk\u002Funsupported-browser-hint\u002Funsupported-browser-hint.js","defer":true},{"src":"\u002F\u002Ftags-eu.tiqcdn.com\u002Futag\u002Ftelekom\u002Fsmarthome\u002Fprod\u002Futag.js","defer":true,"onError":"window.utagError = true;"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"preload","href":"https:\u002F\u002Fwww.telekom.de\u002Fresources\u002Fsites\u002Fphoenix\u002Fstyle\u002Ffont\u002Fteleneo-variable.woff2","as":"font","type":"font\u002Fwoff2","crossorigin":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_1418e776 === 'function') {
    await nuxt_plugin_sentryserver_1418e776(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_a3411424 === 'function') {
    await nuxt_plugin_sentryclient_a3411424(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetouchevents_04448bc4 === 'function') {
    await nuxt_plugin_vuetouchevents_04448bc4(app.context, inject)
  }

  if (typeof nuxt_plugin_jsonld_593ee954 === 'function') {
    await nuxt_plugin_jsonld_593ee954(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clickoutside_2bc8eaac === 'function') {
    await nuxt_plugin_clickoutside_2bc8eaac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_csrfprotection_e5627928 === 'function') {
    await nuxt_plugin_csrfprotection_e5627928(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_dialog_6edfaf84 === 'function') {
    await nuxt_plugin_dialog_6edfaf84(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_98405076 === 'function') {
    await nuxt_plugin_filters_98405076(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_91a8526c === 'function') {
    await nuxt_plugin_globalcomponents_91a8526c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_loader_52e949ee === 'function') {
    await nuxt_plugin_loader_52e949ee(app.context, inject)
  }

  if (typeof nuxt_plugin_portal_4532993c === 'function') {
    await nuxt_plugin_portal_4532993c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_redirect_7657855c === 'function') {
    await nuxt_plugin_redirect_7657855c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_svgxuse_797b723d === 'function') {
    await nuxt_plugin_svgxuse_797b723d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tealium_ddf5c6a2 === 'function') {
    await nuxt_plugin_tealium_ddf5c6a2(app.context, inject)
  }

  if (typeof nuxt_plugin_componentlibrary_0c1b5196 === 'function') {
    await nuxt_plugin_componentlibrary_0c1b5196(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueasynccomputed_f271474a === 'function') {
    await nuxt_plugin_vueasynccomputed_f271474a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescroll_0c9cb62c === 'function') {
    await nuxt_plugin_vuescroll_0c9cb62c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_smoothscroll_68d9071e === 'function') {
    await nuxt_plugin_smoothscroll_68d9071e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_trackingfb_a778f3ca === 'function') {
    await nuxt_plugin_trackingfb_a778f3ca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_expresscheckout_226fe30e === 'function') {
    await nuxt_plugin_expresscheckout_226fe30e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueflipcountdown_13feb424 === 'function') {
    await nuxt_plugin_vueflipcountdown_13feb424(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
