import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5ca2ac04 = () => interopDefault(import('../pages/aktivieren.vue' /* webpackChunkName: "pages/aktivieren" */))
const _767e5a60 = () => interopDefault(import('../pages/angebot-beendet.vue' /* webpackChunkName: "pages/angebot-beendet" */))
const _b69161ec = () => interopDefault(import('../pages/app-download.vue' /* webpackChunkName: "pages/app-download" */))
const _77871f3e = () => interopDefault(import('../pages/applogin.vue' /* webpackChunkName: "pages/applogin" */))
const _80b0e6b2 = () => interopDefault(import('../pages/hilfe/index.vue' /* webpackChunkName: "pages/hilfe/index" */))
const _02d571c8 = () => interopDefault(import('../pages/infomail-abmelden.vue' /* webpackChunkName: "pages/infomail-abmelden" */))
const _351d4638 = () => interopDefault(import('../pages/infos-zum-online-kauf.vue' /* webpackChunkName: "pages/infos-zum-online-kauf" */))
const _37853150 = () => interopDefault(import('../pages/magenta-smarthome-alexa-skill.vue' /* webpackChunkName: "pages/magenta-smarthome-alexa-skill" */))
const _b32686ce = () => interopDefault(import('../pages/magenta-smarthome-app-und-funktionen/index.vue' /* webpackChunkName: "pages/magenta-smarthome-app-und-funktionen/index" */))
const _21554e32 = () => interopDefault(import('../pages/magenta-smarthome-app-updates/index.vue' /* webpackChunkName: "pages/magenta-smarthome-app-updates/index" */))
const _c73dd14c = () => interopDefault(import('../pages/magentazuhause-alexa-skill.vue' /* webpackChunkName: "pages/magentazuhause-alexa-skill" */))
const _b9027518 = () => interopDefault(import('../pages/magentazuhause-app.vue' /* webpackChunkName: "pages/magentazuhause-app" */))
const _3f29fa0f = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _7b65b5c8 = () => interopDefault(import('../pages/newsletter-abmelden.vue' /* webpackChunkName: "pages/newsletter-abmelden" */))
const _b649c63c = () => interopDefault(import('../pages/partner.vue' /* webpackChunkName: "pages/partner" */))
const _c37641aa = () => interopDefault(import('../pages/ratenzahlung.vue' /* webpackChunkName: "pages/ratenzahlung" */))
const _69bc1c32 = () => interopDefault(import('../pages/schnell-starten.vue' /* webpackChunkName: "pages/schnell-starten" */))
const _c2a681be = () => interopDefault(import('../pages/shop-start.vue' /* webpackChunkName: "pages/shop-start" */))
const _1c9f918b = () => interopDefault(import('../pages/so-geht-smart-home.vue' /* webpackChunkName: "pages/so-geht-smart-home" */))
const _0070d5e4 = () => interopDefault(import('../pages/sticky-banner-alexa-skill.vue' /* webpackChunkName: "pages/sticky-banner-alexa-skill" */))
const _8190895c = () => interopDefault(import('../pages/url-generator/index.vue' /* webpackChunkName: "pages/url-generator/index" */))
const _33aa1fec = () => interopDefault(import('../pages/geraete/async-data-functions.js' /* webpackChunkName: "pages/geraete/async-data-functions" */))
const _f2eacfaa = () => interopDefault(import('../pages/hilfe/faq.vue' /* webpackChunkName: "pages/hilfe/faq" */))
const _5ecd0864 = () => interopDefault(import('../pages/hilfe/home-base-wechsel.vue' /* webpackChunkName: "pages/hilfe/home-base-wechsel" */))
const _205b31a6 = () => interopDefault(import('../pages/hilfe/kompatible-geraete/index.vue' /* webpackChunkName: "pages/hilfe/kompatible-geraete/index" */))
const _1a13f0b5 = () => interopDefault(import('../pages/hilfe/kontakt.vue' /* webpackChunkName: "pages/hilfe/kontakt" */))
const _fde1b7e2 = () => interopDefault(import('../pages/hilfe/retoure-und-kuendigung.vue' /* webpackChunkName: "pages/hilfe/retoure-und-kuendigung" */))
const _6a2a69e5 = () => interopDefault(import('../pages/hilfe/zentralen-und-funkstandards.vue' /* webpackChunkName: "pages/hilfe/zentralen-und-funkstandards" */))
const _6ab723bc = () => interopDefault(import('../pages/ideen/alle-ideen.vue' /* webpackChunkName: "pages/ideen/alle-ideen" */))
const _318bc4e9 = () => interopDefault(import('../pages/ideen/energie-sparen.vue' /* webpackChunkName: "pages/ideen/energie-sparen" */))
const _04faf05c = () => interopDefault(import('../pages/ideen/idea-async-data-functions.js' /* webpackChunkName: "pages/ideen/idea-async-data-functions" */))
const _86d36d9c = () => interopDefault(import('../pages/ideen/komfort.vue' /* webpackChunkName: "pages/ideen/komfort" */))
const _374e4c20 = () => interopDefault(import('../pages/ideen/licht.vue' /* webpackChunkName: "pages/ideen/licht" */))
const _475ace22 = () => interopDefault(import('../pages/ideen/sicherheit.vue' /* webpackChunkName: "pages/ideen/sicherheit" */))
const _159bb863 = () => interopDefault(import('../pages/ideen/smarte-tuerschloesser-mit-nuki.vue' /* webpackChunkName: "pages/ideen/smarte-tuerschloesser-mit-nuki" */))
const _02ee2754 = () => interopDefault(import('../pages/ideen/smarter-sonnenschutz-von-eq-3.vue' /* webpackChunkName: "pages/ideen/smarter-sonnenschutz-von-eq-3" */))
const _c1717f92 = () => interopDefault(import('../pages/ideen/unterhaltung.vue' /* webpackChunkName: "pages/ideen/unterhaltung" */))
const _1469e80a = () => interopDefault(import('../pages/ideen/wetter-und-klima-mit-netatmo.vue' /* webpackChunkName: "pages/ideen/wetter-und-klima-mit-netatmo" */))
const _027105f6 = () => interopDefault(import('../pages/magenta-smarthome-app-und-funktionen/wiz-integration.vue' /* webpackChunkName: "pages/magenta-smarthome-app-und-funktionen/wiz-integration" */))
const _a4524b30 = () => interopDefault(import('../pages/magenta-smarthome-app-updates/version-6.5.vue' /* webpackChunkName: "pages/magenta-smarthome-app-updates/version-6.5" */))
const _66820c7a = () => interopDefault(import('../pages/shop/fehler.vue' /* webpackChunkName: "pages/shop/fehler" */))
const _7b2deb1a = () => interopDefault(import('../pages/shop/home-base-2-aktion.vue' /* webpackChunkName: "pages/shop/home-base-2-aktion" */))
const _29a1c668 = () => interopDefault(import('../pages/shop/kundenformular.vue' /* webpackChunkName: "pages/shop/kundenformular" */))
const _5009deeb = () => interopDefault(import('../pages/shop/kundenformular/index.vue' /* webpackChunkName: "pages/shop/kundenformular/index" */))
const _b44fd0a2 = () => interopDefault(import('../pages/shop/kundenformular/identifizierung.vue' /* webpackChunkName: "pages/shop/kundenformular/identifizierung" */))
const _19b18202 = () => interopDefault(import('../pages/shop/kundenformular/login.vue' /* webpackChunkName: "pages/shop/kundenformular/login" */))
const _15b613ee = () => interopDefault(import('../pages/shop/kundenformular/upgrade-identifikation.vue' /* webpackChunkName: "pages/shop/kundenformular/upgrade-identifikation" */))
const _a401ecb2 = () => interopDefault(import('../pages/shop/zuhause-im-blick-paket.vue' /* webpackChunkName: "pages/shop/zuhause-im-blick-paket" */))
const _a9bccb50 = () => interopDefault(import('../pages/weitere-informationen/agb-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/agb-brodos" */))
const _e015daf2 = () => interopDefault(import('../pages/weitere-informationen/sitemap.vue' /* webpackChunkName: "pages/weitere-informationen/sitemap" */))
const _37cdd416 = () => interopDefault(import('../pages/weitere-informationen/ueber-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-brodos" */))
const _58e7135b = () => interopDefault(import('../pages/weitere-informationen/ueber-qivicon.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-qivicon" */))
const _c1426cde = () => interopDefault(import('../pages/hilfe/kompatible-geraete/kompa-metadata.js' /* webpackChunkName: "pages/hilfe/kompatible-geraete/kompa-metadata" */))
const _733b17f9 = () => interopDefault(import('../pages/shop/checkout/adresse.vue' /* webpackChunkName: "pages/shop/checkout/adresse" */))
const _24fa61ca = () => interopDefault(import('../pages/shop/checkout/bestaetigung.vue' /* webpackChunkName: "pages/shop/checkout/bestaetigung" */))
const _e758253a = () => interopDefault(import('../pages/shop/checkout/bestaetigung-teaser.vue' /* webpackChunkName: "pages/shop/checkout/bestaetigung-teaser" */))
const _5871176f = () => interopDefault(import('../pages/shop/checkout/warenkorb.vue' /* webpackChunkName: "pages/shop/checkout/warenkorb" */))
const _32cae306 = () => interopDefault(import('../pages/shop/checkout/zahlungsart.vue' /* webpackChunkName: "pages/shop/checkout/zahlungsart" */))
const _2919ae2b = () => interopDefault(import('../pages/shop/checkout/zusammenfassung.vue' /* webpackChunkName: "pages/shop/checkout/zusammenfassung" */))
const _7e501036 = () => interopDefault(import('../pages/shop/dienst/mza-upgrade.vue' /* webpackChunkName: "pages/shop/dienst/mza-upgrade" */))
const _fb93aca2 = () => interopDefault(import('../pages/shop/dienst/upgrade.vue' /* webpackChunkName: "pages/shop/dienst/upgrade" */))
const _6eb1bbfa = () => interopDefault(import('../pages/shop/verfuegbarkeitsbenachrichtigung/_token.vue' /* webpackChunkName: "pages/shop/verfuegbarkeitsbenachrichtigung/_token" */))
const _93153504 = () => interopDefault(import('../pages/geraete/_slug.vue' /* webpackChunkName: "pages/geraete/_slug" */))
const _e8c9ac98 = () => interopDefault(import('../pages/ideen/_idea.vue' /* webpackChunkName: "pages/ideen/_idea" */))
const _aa54e234 = () => interopDefault(import('../pages/service/_service.vue' /* webpackChunkName: "pages/service/_service" */))
const _4962cc6c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _287f8280 = () => interopDefault(import('../special-pages/shop-list' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivieren",
    component: _5ca2ac04,
    name: "aktivieren"
  }, {
    path: "/angebot-beendet",
    component: _767e5a60,
    name: "angebot-beendet"
  }, {
    path: "/app-download",
    component: _b69161ec,
    name: "app-download"
  }, {
    path: "/applogin",
    component: _77871f3e,
    name: "applogin"
  }, {
    path: "/hilfe",
    component: _80b0e6b2,
    name: "hilfe"
  }, {
    path: "/infomail-abmelden",
    component: _02d571c8,
    name: "infomail-abmelden"
  }, {
    path: "/infos-zum-online-kauf",
    component: _351d4638,
    name: "infos-zum-online-kauf"
  }, {
    path: "/magenta-smarthome-alexa-skill",
    component: _37853150,
    name: "magenta-smarthome-alexa-skill"
  }, {
    path: "/magenta-smarthome-app-und-funktionen",
    component: _b32686ce,
    name: "magenta-smarthome-app-und-funktionen"
  }, {
    path: "/magenta-smarthome-app-updates",
    component: _21554e32,
    name: "magenta-smarthome-app-updates"
  }, {
    path: "/magentazuhause-alexa-skill",
    component: _c73dd14c,
    name: "magentazuhause-alexa-skill"
  }, {
    path: "/magentazuhause-app",
    component: _b9027518,
    name: "magentazuhause-app"
  }, {
    path: "/newsletter",
    component: _3f29fa0f,
    name: "newsletter"
  }, {
    path: "/newsletter-abmelden",
    component: _7b65b5c8,
    name: "newsletter-abmelden"
  }, {
    path: "/partner",
    component: _b649c63c,
    name: "partner"
  }, {
    path: "/ratenzahlung",
    component: _c37641aa,
    name: "ratenzahlung"
  }, {
    path: "/schnell-starten",
    component: _69bc1c32,
    name: "schnell-starten"
  }, {
    path: "/shop-start",
    component: _c2a681be,
    name: "shop-start"
  }, {
    path: "/so-geht-smart-home",
    component: _1c9f918b,
    name: "so-geht-smart-home"
  }, {
    path: "/sticky-banner-alexa-skill",
    component: _0070d5e4,
    name: "sticky-banner-alexa-skill"
  }, {
    path: "/url-generator",
    component: _8190895c,
    name: "url-generator"
  }, {
    path: "/geraete/async-data-functions",
    component: _33aa1fec,
    name: "geraete-async-data-functions"
  }, {
    path: "/hilfe/faq",
    component: _f2eacfaa,
    name: "hilfe-faq"
  }, {
    path: "/hilfe/home-base-wechsel",
    component: _5ecd0864,
    name: "hilfe-home-base-wechsel"
  }, {
    path: "/hilfe/kompatible-geraete",
    component: _205b31a6,
    name: "hilfe-kompatible-geraete"
  }, {
    path: "/hilfe/kontakt",
    component: _1a13f0b5,
    name: "hilfe-kontakt"
  }, {
    path: "/hilfe/retoure-und-kuendigung",
    component: _fde1b7e2,
    name: "hilfe-retoure-und-kuendigung"
  }, {
    path: "/hilfe/zentralen-und-funkstandards",
    component: _6a2a69e5,
    name: "hilfe-zentralen-und-funkstandards"
  }, {
    path: "/ideen/alle-ideen",
    component: _6ab723bc,
    name: "ideen-alle-ideen"
  }, {
    path: "/ideen/energie-sparen",
    component: _318bc4e9,
    name: "ideen-energie-sparen"
  }, {
    path: "/ideen/idea-async-data-functions",
    component: _04faf05c,
    name: "ideen-idea-async-data-functions"
  }, {
    path: "/ideen/komfort",
    component: _86d36d9c,
    name: "ideen-komfort"
  }, {
    path: "/ideen/licht",
    component: _374e4c20,
    name: "ideen-licht"
  }, {
    path: "/ideen/sicherheit",
    component: _475ace22,
    name: "ideen-sicherheit"
  }, {
    path: "/ideen/smarte-tuerschloesser-mit-nuki",
    component: _159bb863,
    name: "ideen-smarte-tuerschloesser-mit-nuki"
  }, {
    path: "/ideen/smarter-sonnenschutz-von-eq-3",
    component: _02ee2754,
    name: "ideen-smarter-sonnenschutz-von-eq-3"
  }, {
    path: "/ideen/unterhaltung",
    component: _c1717f92,
    name: "ideen-unterhaltung"
  }, {
    path: "/ideen/wetter-und-klima-mit-netatmo",
    component: _1469e80a,
    name: "ideen-wetter-und-klima-mit-netatmo"
  }, {
    path: "/magenta-smarthome-app-und-funktionen/wiz-integration",
    component: _027105f6,
    name: "magenta-smarthome-app-und-funktionen-wiz-integration"
  }, {
    path: "/magenta-smarthome-app-updates/version-6.5",
    component: _a4524b30,
    name: "magenta-smarthome-app-updates-version-6.5"
  }, {
    path: "/shop/fehler",
    component: _66820c7a,
    name: "shop-fehler"
  }, {
    path: "/shop/home-base-2-aktion",
    component: _7b2deb1a,
    name: "shop-home-base-2-aktion"
  }, {
    path: "/shop/kundenformular",
    component: _29a1c668,
    children: [{
      path: "",
      component: _5009deeb,
      name: "shop-kundenformular"
    }, {
      path: "identifizierung",
      component: _b44fd0a2,
      name: "shop-kundenformular-identifizierung"
    }, {
      path: "login",
      component: _19b18202,
      name: "shop-kundenformular-login"
    }, {
      path: "upgrade-identifikation",
      component: _15b613ee,
      name: "shop-kundenformular-upgrade-identifikation"
    }]
  }, {
    path: "/shop/zuhause-im-blick-paket",
    component: _a401ecb2,
    name: "shop-zuhause-im-blick-paket"
  }, {
    path: "/weitere-informationen/agb-brodos",
    component: _a9bccb50,
    name: "weitere-informationen-agb-brodos"
  }, {
    path: "/weitere-informationen/sitemap",
    component: _e015daf2,
    name: "weitere-informationen-sitemap"
  }, {
    path: "/weitere-informationen/ueber-brodos",
    component: _37cdd416,
    name: "weitere-informationen-ueber-brodos"
  }, {
    path: "/weitere-informationen/ueber-qivicon",
    component: _58e7135b,
    name: "weitere-informationen-ueber-qivicon"
  }, {
    path: "/hilfe/kompatible-geraete/kompa-metadata",
    component: _c1426cde,
    name: "hilfe-kompatible-geraete-kompa-metadata"
  }, {
    path: "/shop/checkout/adresse",
    component: _733b17f9,
    name: "shop-checkout-adresse"
  }, {
    path: "/shop/checkout/bestaetigung",
    component: _24fa61ca,
    name: "shop-checkout-bestaetigung"
  }, {
    path: "/shop/checkout/bestaetigung-teaser",
    component: _e758253a,
    name: "shop-checkout-bestaetigung-teaser"
  }, {
    path: "/shop/checkout/warenkorb",
    component: _5871176f,
    name: "shop-checkout-warenkorb"
  }, {
    path: "/shop/checkout/zahlungsart",
    component: _32cae306,
    name: "shop-checkout-zahlungsart"
  }, {
    path: "/shop/checkout/zusammenfassung",
    component: _2919ae2b,
    name: "shop-checkout-zusammenfassung"
  }, {
    path: "/shop/dienst/mza-upgrade",
    component: _7e501036,
    name: "shop-dienst-mza-upgrade"
  }, {
    path: "/shop/dienst/upgrade",
    component: _fb93aca2,
    name: "shop-dienst-upgrade"
  }, {
    path: "/shop/verfuegbarkeitsbenachrichtigung/:token?",
    component: _6eb1bbfa,
    name: "shop-verfuegbarkeitsbenachrichtigung-token"
  }, {
    path: "/geraete/:slug?",
    component: _93153504,
    name: "geraete-slug"
  }, {
    path: "/ideen/:idea?",
    component: _e8c9ac98,
    name: "ideen-idea"
  }, {
    path: "/service/:service?",
    component: _aa54e234,
    name: "service-service"
  }, {
    path: "/",
    component: _4962cc6c,
    name: "index"
  }, {
    path: "/shop/:category?/:filter?/:page?",
    component: _287f8280,
    name: "shop-list"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
